import { get, getCodeImage } from "../../../utils/weather";
import { useEffect, useState } from "react";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import SocialMedia from "../../SocialMedia";
import FilterDramaIcon from "@material-ui/icons/FilterDrama";
import fetchData from "../../../utils/fetch";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import LocationDialog from "../../Dialog/LocationDialog";
import iconSet from "../../../public/selection.json";
import IcomoonReact from "icomoon-react";

export default function BasicNews() {
  const today = format(new Date(), "EEEE dd/LL", { locale: fr });
  const todayhhmm = "12:30"; // format(new Date(), "p", { locale: fr });
  const [city, setCity] = useState(null);
  const [degree, setDegree] = useState(null);
  const [meteoIconName, setMeteoIconName] = useState("");
  const [isNight, setIsNight] = useState(null);
  const [askPositionDialog, setAskPositionDialog] = useState(false);
  const [posAuth, setPosAuth] = useState(false);
  const [infosReady, setInfosReady] = useState(false);
  const minutesOfDay = function (hour) {
    let splitedHour = hour.split(":");
    return parseInt(splitedHour[0]) * 60 + parseInt(splitedHour[1]);
  };
  const askPosition = () => {
    let alreadyAsked = localStorage.getItem("askedPosition");
    if (alreadyAsked == null) {
      setAskPositionDialog(true);
    } else if (alreadyAsked == "true") {
      handlePosition();
    }
  };

  const handleAskPosition = (res) => {
    localStorage.setItem("askedPosition", res);
    setPosAuth(res);
    setAskPositionDialog(false);
    if (res == true) {
      handlePosition();
    }
  };

  const handlePosition = () => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      let locationData = await fetchData(
        `https://api-adresse.data.gouv.fr/reverse/?lon=${position.coords.longitude}&lat=${position.coords.latitude}`,
        "GET"
      );
      let weather = await get(locationData?.features[0]?.properties.postcode);
      console.log(locationData, weather);

      let degree = weather.main.temp;

      /*let sunsethhmm = convert12HourTo24(
        weather.sys.sunset
      );
      let sunrisehhmm = convert12HourTo24(
        weather.sys.sunrise
      );
      let isItNight =
        minutesOfDay(todayhhmm) > minutesOfDay(sunsethhmm) &&
        minutesOfDay(todayhhmm) < minutesOfDay(sunrisehhmm);

*/
      let weatherIcon = await getCodeImage(null);

      setCity(
        locationData?.features[0]?.properties?.city ?? "Localisation impossible"
      );
      setDegree(Math.round(degree));
      /*setIsNight(isItNight);*/
      setMeteoIconName(weatherIcon);
      setInfosReady(true);
    });
  };

  useEffect(() => {
    setPosAuth(localStorage.getItem("askedPosition"));
    setTimeout(function () {
      askPosition();
    }, 5000);
  }, []);

  return (
    <>
      <div className="bn-container">
        <div className="bn-sub-container">
          <div className="bn-left-part">
            {posAuth ? (
              infosReady ? (
                <>
                  <div className="bn-temperature-container">
                    {meteoIconName && (
                      <FilterDramaIcon className="mr-4" fontSize="small" />
                    )}
                    <p className="bn-degree">{degree}°C</p>
                  </div>
                  <div className="bn-location">
                    <LocationOnIcon
                      fontSize="small"
                      style={{ fill: "white" }}
                      className="mr-4"
                    />
                    <p>{city}</p>
                  </div>
                </>
              ) : (
                <p className="locat-desactivated">Localisation en cours...</p>
              )
            ) : (
              <p className="locat-desactivated">Localisation désactivée</p>
            )}
            <div className="bn-date">
              <IcomoonReact
                iconSet={iconSet}
                icon="ico-calendar"
                size={20}
                color="#FFF"
                className="mr-4"
              />
              <p>{today}</p>
            </div>
          </div>
          {/* Right part */}

          <SocialMedia classNameContainer="flex items-center justify-end space-x-3" />
        </div>
      </div>
      <LocationDialog
        open={askPositionDialog}
        onClose={() => setAskPositionDialog(false)}
        handleResponse={handleAskPosition}
      />
    </>
  );
}
